import React, { useState } from 'react';  
import axios from 'axios';  
  
const EditUsersPage = () => {  
  const [name, setName] = useState('');  
  const [email, setEmail] = useState('');  
  const [preference, setPreference] = useState('');  
  const [rank, setRank] = useState(0);  
  
  const handleSubmit = async (e) => {  
    e.preventDefault();  
    try {  
      const data = {  
        name,  
        email,  
        preference,  
        rank,  
      };  
  
      const response = await axios.post(  
        process.env.REACT_APP_MONGODB_DATA_API_URL + '/action/insertOne',  
        data,  
        {  
          headers: {  
            'Content-Type': 'application/json',  
            'apiKey': process.env.REACT_APP_MONGODB_DATA_API_KEY,  
          },  
        }  
      );  
  
      console.log('Document inserted:', response.data);  
    } catch (error) {  
      console.error('Error:', error);  
    }  
  };  
  
  return (  
    <div className="EditUsersPage">  
      <form onSubmit={handleSubmit}>  
        <input  
          type="text"  
          placeholder="Name"  
          value={name}  
          onChange={(e) => setName(e.target.value)}  
        />  
        <input  
          type="email"  
          placeholder="Email"  
          value={email}  
          onChange={(e) => setEmail(e.target.value)}  
        />  
        <input  
          type="text"  
          placeholder="Preference"  
          value={preference}  
          onChange={(e) => setPreference(e.target.value)}  
        />  
        <input  
          type="number"  
          placeholder="Rank"  
          value={rank}  
          onChange={(e) => setRank(e.target.value)}  
        />  
        <button type="submit">Submit</button>  
      </form>  
    </div>  
  );  
};  
  
export default EditUsersPage;  
