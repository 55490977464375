import React, { useState } from 'react';  
  
const AllocationPage = () => {  

    const [name, setName] = useState('');  
    const [email, setEmail] = useState('');  
    const [dictionary, setDictionary] = useState({}); 

    const handleAllocationChange = (event) => {  
        console.log('Selected allocation:', event.target.value);  
    };  
    
    const handlePropertyChange = (event) => {  
        console.log('Selected property:', event.target.value);  
    };  
    
    const handleDealTypeChange = (event) => {  
        console.log('Selected deal type:', event.target.value);  
    };  
    const handleDateChange = (event) => {  
        console.log('Selected date:', event.target.value);  
    };  

    const handleNameChange = (event) => {  
        setName(event.target.value);  
      };  
      
      const handleEmailChange = (event) => {  
        setEmail(event.target.value);  
      };  
      
      const handleClick = () => {  
        setDictionary({ ...dictionary, [name]: email });  
      }; 

      const handleDelete = (key) => {  
        const newDictionary = { ...dictionary };  
        delete newDictionary[key];  
        setDictionary(newDictionary);  
      }; 

    return (  
        <div>  
        <h1>Input your Deal information here</h1>  
        <select onChange={handleAllocationChange}>  
            <option value="D01">D01</option>  
            <option value="D02">D02</option>  
            <option value="D03">D03</option>  
        </select>  
        <select onChange={handlePropertyChange}>  
            <option value="Rent">Rent</option>  
            <option value="HDB">HDB</option>  
            <option value="Landed">Landed</option>  
        </select>  
        <select onChange={handleDealTypeChange}>  
            <option value="Seller">Seller</option>  
            <option value="Buyer">Buyer</option>  
            <option value="Others">Others</option>  
        </select>  
        <input type="date" onChange={handleDateChange} />  

            <div className="input-container">  
                    <input type="text" placeholder="Name" value={name} onChange={handleNameChange} />  
                    <input type="email" placeholder="Email address" value={email} onChange={handleEmailChange} />  
                    <button onClick={handleClick}>Add to Dictionary</button>  
                    <table>  
                    <thead>  
                        <tr>  
                        <th>Name</th>  
                        <th>Email</th>  
                        <th>Action</th>  
                        </tr>  
                    </thead>  
                    <tbody>  
                        {Object.entries(dictionary).map(([key, value]) => (  
                        <tr key={key}>  
                            <td>{key}</td>  
                            <td>{value}</td>  
                            <td>  
                            <button onClick={() => handleDelete(key)}>Delete</button>  
                            </td>  
                        </tr>  
                        ))}  
                    </tbody>  
                    </table>  
            </div>
        </div> 
    );  
};  
  
export default AllocationPage;  
