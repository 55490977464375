import { BrowserRouter, Route, Routes } from 'react-router-dom';  
import logo from './logo.svg';  
import './App.css';  
import AllocationPage from "./pages/allocation" 
import EditUsersPage from "./pages/editusers" 
  
function App() {  
  return (  
    <BrowserRouter>  
      <Routes>  
        <Route  
          path="/allocate"  
          element={<AllocationPage />}  
        />  
        <Route  
          path="/editusers"  
          element={<EditUsersPage />}  
        />  
      </Routes>  
    </BrowserRouter>  
  );  
}  
  
export default App;  
